@import '@angular/material/theming';
@import 'src/styles/variables';

@mixin dra-mat-tab-nav-bar-theme($config) {
  .mat-tab-nav-bar {
    border: 0;

    .mat-tab-link {
      color: #9599c3; // TODO add variable
      font-family: $font-stack;
      font-size: 14px;
      min-width: unset;
      opacity: 1;
      padding: 0;
      margin: 0 16px;

      &.mat-tab-label-active {
        color: $white-color;
        font-weight: 600;
      }
    }

    .mat-ink-bar {
      background-color: $white-color !important;
      height: 2px;
    }

    .mat-tab-header-pagination-chevron {
      border-color: #9599c3;
    }
  }

  .piece-inspector-tab-nav-bar {
    border: 0;

    .mat-tab-link {
      color: #9599c3; // TODO add variable
      font-family: $font-stack;
      font-size: 14px;
      min-width: 90px;
      opacity: 1;
      padding: 0;
      margin: 0 16px;

      &.mat-tab-label-active {
        color: $primary;
        font-weight: 600;
      }
    }

    .mat-ink-bar {
      background-color: $primary !important;
      height: 2px;
    }

    .mat-tab-header-pagination-chevron {
      border-color: #9599c3;
    }
  }
}
