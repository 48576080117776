@import 'variables';

header {
  .title-with-icon {
    display: flex;
    align-items: center;
  }

  .card-icon {
    align-items: center;
    background-color: rgba($primary, 0.05);
    border-radius: 8px;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 16px;
    width: 42.4px;

    &.warn {
      background-color: rgba($error, 0.05);
    }
  }
}
