@import '@angular/material/theming';
@import 'src/styles/variables';

@mixin dra-mat-button-theme($config) {
  .mat-flat-button {
    border-radius: 8px !important;
    font-size: 16px;
    line-height: 40px !important;
  }
}
