/******************************************************************************
                                Text Inputs
******************************************************************************/
.form-group {
  margin-bottom: 16px;

  /*** Default ****/
  // input[type='text'] {
  //   box-sizing: border-box;
  //   border: 1px solid rgba($dark, 0.5);
  //   border-radius: 8px;
  //   font-family: $font-stack;
  //   font-size: 14px;
  //   padding: 8px;
  //   width: 100%;
  // }

  label {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 4px;

    // For the 'required' asterisk
    .required {
      color: $primary;
    }
  }

  /*** Focus State ***/
  input[type='text']:focus {
    border: 1px solid $primary;
  }

  &:focus-within {
    label {
      color: $primary;
    }
  }

  /*** Disabled State ***/
  input[type='text']:disabled {
    border: 1px solid rgba($dark, 0.25);
    background: rgba($black, 0.05);
  }

  /*** Error State ***/
  .ng-dirty.ng-invalid:not(form) {
    border: 1px solid $error;
  }
}

/******************************************************************************
                            Checkbox Toggle Switch
******************************************************************************/
/*** The switch (box around the slider) ***/
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  // Hide default HTML checkbox
  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $primary;

      &:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }
  }

  /*** Slider (white circle inside the switch) ***/
  .slider {
    border-radius: 17px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &:before {
      border-radius: 50%;
      position: absolute;
      content: '';
      height: 11px;
      width: 11px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  }
}

.switch-text {
  font-size: 12px;
  margin-left: 8px;
  opacity: 0.5;
}

/******************************************************************************
                            Checkbox Chips
******************************************************************************/
.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.ck-button {
  margin: 4px;
  background-color: rgba($black, 0.05);
  border-radius: 8px;
  color: rgba($black, 0.5);
  font-weight: 500;
  padding: 4px 6px;

  &.active {
    background-color: $primary;
  }

  label {
    display: inline-block;

    input {
      margin-right: 100px;
    }

    span {
      text-align: center;
      padding: 3px 0px;
      display: block;
    }
  }

  input:checked + span {
    background-color: $primary;
    color: $white;
    font-weight: 600;
  }
}
