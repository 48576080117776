// Import library functions for theme creation.
@import '@angular/material/theming';
@import 'src/styles/variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dra-mat-button-toggle-group-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Define any styles affected by the theme.
  .drpw-mat-button-toggle-group {
    &.mat-button-toggle-group-appearance-standard {
      border-radius: 8px;
      height: 36px;

      .mat-button-toggle-label-content {
        height: 36px;
        // TODO: This is hacky. Fix.
        line-height: 34px;
      }
    }

    .mat-button-toggle {
      background: rgba($black, 0.05);
      color: rgba($black, 0.25);

      &.mat-button-toggle-checked {
        color: $black;
        // TODO: Get the border style correct
        // border: 1px solid rgba($black, 0.5);
      }
    }
  }
}
