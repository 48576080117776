// Import library functions for theme creation.
@import '@angular/material/theming';
@import 'src/styles/variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dra-mat-tab-group-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Styles for MAT-TAB-GROUPS
  .drpw-mat-tab-group {
    .mat-tab-header {
      border: none;
    }

    .mat-tab-label {
      justify-content: flex-start;
      padding: 0 12px 0 12px;
      min-width: 1px;

      .mat-tab-label-content {
        font-family: $font-stack;
        font-size: 12px;
        font-weight: 600;
        // opacity: 0.5;
      }

      &.mat-tab-label-active {
        opacity: 1;

        .mat-tab-label-content {
          opacity: 1;
        }
      }
    }

    .mat-ink-bar {
      background-color: $alt-1;
      width: 20px !important;
      margin-left: 12px;
    }
  }

  // Styles for tab NAVS
  .drpw-mat-tab-nav {
    border: none;

    .mat-tab-link {
      font-size: 12px;
      font-weight: 600;
      justify-content: flex-start;
      min-width: 112px;
      padding: 0;

      &.mat-tab-label-active {
        opacity: 1;
      }
    }

    .mat-ink-bar {
      background-color: $alt-1;
      width: 20px !important;
    }
  }
}

.piece-inspector-confirmation-dialog-mat-tab-group {
  .mat-tab-label {
    opacity: 1;
    color: rgba($black, 0.87);
    font-size: 16px;
    padding: 0;
    justify-content: flex-start;
    min-width: 1px;
    margin-right: 16px;
    height: 56px;
    background-color: transparent;
  }

  .mat-tab-label-active {
    font-weight: 600;
  }
}
