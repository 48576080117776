// Import library functions for theme creation.
@import '@angular/material/theming';
@import 'src/styles/variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dra-mat-form-field-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Define any styles affected by the theme.
  .drpw-mat-form-field {
    background: $white;
    &.dark {
      background: rgba($black, 0.05);
    }
    border-radius: 8px;
    font-family: $font-stack;
    font-size: 14px;
    margin: 16px 0;
    padding: 2px 8px;
    width: 100%;

    // TODO: Fix disappearing placeholder bug
    &.mat-focused,
    &.ng-dirty {
      .mat-form-field-label-wrapper {
        label {
          display: none;
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border: none;
      padding: 7px 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-suffix {
      // TODO: Find a vertical centering fix if possible.
      top: 0.28em;
    }

    // TODO: Move out these styles if needed
    &.pars-form {
      border: 1px solid rgba($black, 0.5);
      margin-top: 0;

      .mat-form-field-flex {
        align-items: center;

        .mat-form-field-suffix {
          margin-bottom: 8px;
        }
      }
    }
  }

  // .mat-form-field-appearance-outline {
  //   .mat-form-field-wrapper {
  //     .mat-form-field-flex {
  //       padding: 0 8px 0 4px;

  //       .mat-form-field-outline {
  //         .mat-form-field-outline-start {
  //           border-radius: 8px 0 0 8px;
  //         }
  //         .mat-form-field-outline-end {
  //           border-radius: 0 8px 8px 0;
  //         }
  //       }

  //       .mat-form-field-prefix {
  //         margin-right: 8px;
  //       }

  //       .mat-form-field-infix {
  //         padding: 0.5em 0;
  //       }
  //     }
  //   }

  //   .mat-select-value {
  //     position: relative;
  //     bottom: 0.25em;
  //   }

  //   &.mat-form-field-disabled {
  //     .mat-form-field-outline {
  //       background-color: $black-color-lightest;
  //       border-radius: 8px;
  //     }
  //   }
  // }
}

.dra-mat-form-field {
  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}

.range-picker-mat-form-field {
  background: $white;
  border-radius: 8px;
  font-family: $font-stack;
  font-size: 14px;
  padding: 4px 8px 0 44px;
  height: 36px;
  cursor: pointer;
  width: 100%;

  .mat-form-field-infix {
    border: none;
    padding: 7px 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-suffix {
    display: none;
  }
}
