// Import library functions for theme creation.
@import '@angular/material/theming';
@import 'src/styles/variables';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dra-mat-table-theme($theme) {
  // Extract the palettes you need from the theme definition.
  // $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Define any styles affected by the theme.
  .drpw-mat-table {
    width: 100%;

    border-collapse: separate;
    border-radius: 8px;
    -moz-border-radius: 8px;

    tr.mat-header-row {
      background: rgba($black, 0.05);
      color: rgba($black, 0.5);
      height: 36px;
      text-transform: uppercase;

      th.mat-header-cell {
        font-weight: 600;
        border: none;
        padding-top: 13px;
      }

      th.mat-header-cell:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      th.mat-header-cell:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      th.mat-sort-header-sorted {
        color: $primary;
      }

      .mat-sort-header-button {
        text-transform: uppercase;
      }
    }

    td.mat-cell {
      padding-top: 16px;
    }
  }

  .mat-sort-header-arrow[style] {
    .mat-sort-header-stem {
      display: none;
    }
    .mat-sort-header-indicator {
      opacity: 1;
      color: $black;
      font-weight: bold;

      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }

  .mat-sort-header-indicator {
    &::before {
      content: '';
      top: 0.1em;
      position: absolute;
      color: $primary;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 4px;
      transform: rotate(180deg);
      opacity: 0.6;
    }
  }

  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          top: 0.1em;
          transform: rotate(180deg);
          opacity: 1;
        }
      }
    }
  }

  [aria-sort='descending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          top: -0.6em;
          transform: rotate(0);
          opacity: 1;
        }
      }
    }
  }
}
